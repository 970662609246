import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: _ctx.formValues,
        rules: _ctx.rules,
        "label-width": "120px",
        class: "demo-ruleForm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "選擇推薦主題",
            prop: "value"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.formValues.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValues.value) = $event)),
                filterable: "",
                placeholder: "選擇主題"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topicOptions, (topicOption) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: topicOption.id,
                      label: topicOption.name,
                      value: topicOption.id
                    }, null, 8, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                loading: _ctx.isUpdating,
                type: "primary",
                onClick: _ctx.submitForm,
                disabled: Number(_ctx.config) === Number(_ctx.formValues.value)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.config ? '更新' : '新增'), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }))
}