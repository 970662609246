
import { ConfigKey } from '@/services/api';

import { defineComponent, ref } from 'vue';
import RecommendedTopicDetail from './components/RecommendedTopicDetail.vue';

export default defineComponent({
  components: {
    RecommendedTopicDetail
  },
  setup() {
    const activeTab = ref('mobile-recommended');

    return {
      activeTab,
      ConfigKey
    };
  }
});
