
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  watch,
  onMounted,
  PropType
} from 'vue';

import {
  ElMessage,
  ElForm
} from 'element-plus';

import { Rules } from 'async-validator';
import useFormErrors from '@/utils/validate';
import { ConfigKey, ConfigValue, getConfig, getTopics, MAX_PER_PAGE, ResponseError, updateConfig, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';

const DEFAULT_FORM_VALUES: ConfigValue = {
  value: ''
};

const rules: Rules = {
  value: [
    {
      required: true,
      message: '此為必填欄位'
    }
  ]
};

export default defineComponent({
  props: {
    type: {
      type: String as PropType<ConfigKey>,
      required: true,
      default: ''
    }
  },
  setup(props) {
    const { type } = toRefs(props);
    const isUpdating = ref(false);

    const topicOptions = ref();
    const config = ref();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });

    const fetchConfig = async() => {
      const { data } = await getConfig({ key: type.value });
      config.value = data;
    };

    const fetchTopics = async() => {
      topicOptions.value = (await getTopics({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const getTopicLable = () => {
      topicOptions.value.forEach(topic => {
        if (topic.id === Number(config.value)) {
          formValues.value.value = topic.id as string;
        }
      });
    };

    watch([config, topicOptions], () => {
      if (!config.value) return;

      if (config.value && topicOptions.value) {
        getTopicLable();
      }
    });

    const submitForm = () => {
      const form = unref(formRef);

      form && form.validate(async(valid: boolean) => {
        if (valid) {
          formValues.value.value = String(formValues.value.value);
          isUpdating.value = true;

          try {
            const response = await updateConfig({
              key: type.value,
              data: formValues.value
            });

            if (response) {
              ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);
              isUpdating.value = false;

              fetchConfig();
            }
          } catch (err) {
            const error = err as ResponseError;
            ElMessage.error(error.response?.data.message);
            isUpdating.value = false;
          }
        }
      });
    };

    onMounted(() => {
      fetchConfig();
      fetchTopics();
    });

    return {
      rules,
      formRef,
      formValues,
      isUpdating,
      submitForm,
      topicOptions,
      config
    };
  }
});
