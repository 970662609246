import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecommendedTopicDetail = _resolveComponent("RecommendedTopicDetail")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      type: "card"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "手機版熱門推薦影片",
          name: "mobile-recommended"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_RecommendedTopicDetail, {
              type: _ctx.ConfigKey.MOBILE_RECOMMEND_TOPIC
            }, null, 8, ["type"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_tab_pane, {
          label: "無搜尋結果推薦主題",
          name: "no-result-recommended"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_RecommendedTopicDetail, {
              type: _ctx.ConfigKey.SEARCH_RECOMMEND_TOPIC
            }, null, 8, ["type"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}